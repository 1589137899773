import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import * as jose from "jose";
import { getLastUpdate } from "../services/firebaseCrud";
import { tableStyles } from "../tableStyles";
import { Header } from "./Header";
import { SimpleDataTable } from "./Table/SimpleDataTable";

export const TableScreen = () => {
	const [data, setData] = React.useState(null);
	const [lastUpdate, setLastUpdate] = React.useState(null);
	const [magasin, setMagasin] = React.useState(null);

	const Styles = tableStyles;

	const { getAccessTokenSilently } = useAuth0();

	React.useEffect(() => {
		getAccessToken();
	}, []);

	async function getResasFormApi(token, magasin) {
		const res = await fetch(
			`https://api-gateway.zagogroupe.com/api/public/client/${magasin}/reservation`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		const data = await res.json();
		setData(data);
	}

	async function getAccessToken() {
		try {
			const token = await getAccessTokenSilently();
			const secret = jose.decodeJwt(token);
			setMagasin(secret.tenant_id);
            getResasFormApi(token, secret.tenant_id);
			getLastUpdate(secret.tenant_id).then((res) =>
				setLastUpdate(new Date(res * 1000).toLocaleString("fr-FR"))
			);
		} catch (e) {
			console.log(e);
		}
	}

	return (
		<div className="site-card-border-less-wrapper table-container">
			<div className="table-box">
				<div style={{ height: "100vh" }}>
					{window.location.hostname.includes("zago") ? (
						<Header image="/images/logo-zago.png" />
					) : (
						<>
							<Header image="/images/grenier-alpin.png" />
						</>
					)}
					{data ? (
						<Styles>
							<SimpleDataTable
								data={data}
								magasin={magasin}
								lastUpdate={lastUpdate}
							/>
						</Styles>
					) : (
						<strong><span>📑</span> Votre liste est vide !!!</strong>
					)}
				</div>
			</div>
		</div>
	);
};
