import React from "react";
import "./App.css";
import "antd/dist/antd.min.css";
import { useAuth0 } from "@auth0/auth0-react";
import LoginCard from "./components/Authentication/LoginCard";
import { TableScreen } from "./components/TableScreen";

function App() {


	const {
		loginWithRedirect,
		isAuthenticated
	} = useAuth0();


	return (
		<>
			{!isAuthenticated ? (
				<LoginCard
					isAuthenticated={isAuthenticated}
					loginWithRedirect={loginWithRedirect}
				/>
			) : (
				<TableScreen />
			)}
		</>
	);
}

export default App;
