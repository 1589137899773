import React, { useState } from "react";
import { Modal, Button } from "antd";
import { MailOutlined, CheckOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";

const ModalSMS = (props) => {
	const resa = props.resa;
	const magasin = props.magasin;
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [token, setToken] = useState(null);
	const emetteur = switchMagasin(magasin);

	const { getAccessTokenSilently } = useAuth0();

	React.useEffect(() => {
		getAccessToken();
	}, []);

	async function getAccessToken() {
		try {
			setToken(await getAccessTokenSilently());
		} catch (e) {
			console.log(e);
		}
	}

	function switchMagasin(magasin) {
		switch (magasin) {
			case "1740404":
				return "ZAGO ANNEMASSE";
			case "1740349":
				return "ZAGO ANNECY";
			case "1690155":
				return "ZAGO ST PRIEST";
			case "1740419":
				return "ZAGO SALLANCHES";
			case "1740372":
				return "GRENIER ALPIN SEYNOD";
			default:
				return "ZAGO";
		}
	}

	const firstMsg = (magasin) => {
		if (magasin === "ZAGO Annemasse") {
			return `Bonne nouvelle ! Nous avons le plaisir de vous informer de la réception de tout ou une partie de votre commande ZAGO chez notre partenaire MDA ELECTROMENAGER situé 2 rue des artisans – 74100 Ville La Grand. \n\n`;
		} else {
			return `Bonne nouvelle ! Votre commande ZAGO est arrivée. \nVenez récupérer dès aujourd'hui le ou les article(s) suivant(s) :\n\n`;
		}
	};
	const getSecondMsg = (magasin) => {
		switch (magasin) {
			case "1740349":
				return "\nNous vous remercions de venir retirer votre commande sur les horaires d'ouverture de votre magasin : du lundi au vendredi de 10h à 12h30 et de 14h à 19h ainsi que le samedi de 10h à 13h au 84 Boulevard Costa de Beauregard, 74600 SEYNOD.\n\nPour le chargement, veuillez s'il vous plaît venir accompagné(e)s et prévoyez un véhicule adapté.\nDes questions ?\nContactez-nous au 04 58 10 08 20.\n\nToute l'équipe ZAGO vous remercie. A très vite !";
			case "1740404":
				return "\nNous vous invitons à venir la récupérer sous dizaine pendant les horaires d'ouverture suivants : Du lundi au vendredi 10h-12h / 14h-18h45, Le samedi 10h-18h45. n\nN'oubliez pas de passer d'abord au magasin pour récupérer votre bon de retrait au 32 Rue de la Résistance, 74100 Annemasse. \nDes questions ? Contactez-nous au 04 58 10 02 62.\n\nToute l'équipe ZAGO vous remercie. A très vite !";
			case "1690155":
				return "\nNous vous remercions de venir retirer votre commande sur les horaires d'ouverture de votre magasin : du lundi au samedi de 10h à 19h au 196 Rte de Grenoble, 69800 Saint-Priest.\n\nPour le chargement, veuillez s'il vous plaît venir accompagné(e)s et prévoyez un véhicule adapté.\nDes questions ? Contactez-nous au 04 87 77 94 80.\n\nToute l'équipe ZAGO vous remercie. A très vite !";
			case "1740419":
				return "\nNous vous remercions de venir retirer votre commande sur les horaires d'ouverture de votre magasin : du lundi au vendredi de 10h à 12h30 et de 14h à 19h ainsi que le samedi de 10h à 19h non stop, au 468 Clos de l'Ile Roche, 74700 Sallanches.\n\nPour le chargement, veuillez s'il vous plaît venir accompagné(e)s et prévoyez un véhicule adapté.\nDes questions ? Contactez-nous au 04 50 98 14 64.\n\nToute l'équipe ZAGO vous remercie. A très vite !";
			case "1740372":
				return "\nNous vous remercions de venir retirer votre commande sur les horaires d'ouverture de votre magasin : du mardi au vendredi de 10h à 12h et de 14h à 18h30 ainsi que le samedi de 10h à 12h au 507 Avenue d'Aix-les-Bains, 74600 SEYNOD.\n\nPour le chargement, veuillez s'il vous plaît venir accompagné(e)s et prévoyez un véhicule adapté.\nDes questions ? Contactez-nous au 04 58 10 02 61.\n\nToute l'équipe Grenier Alpin vous remercie. Arvi pa !";
			default:
				return "\nNous vous remercions de venir retirer votre commande sur les horaires d'ouverture de votre magasin : du lundi au vendredi de 10h à 12h30 et de 14h à 19h ainsi que le samedi de 10h à 13h au 84 Boulevard Costa de Beauregard, 74600 SEYNOD.\n\nPour le chargement, veuillez s'il vous plaît venir accompagné(e)s et prévoyez un véhicule adapté.\nDes questions ?\nContactez-nous au 04 58 10 08 20.\n\nToute l'équipe ZAGO vous remercie. A très vite !";
		}
	};

	const showModal = () => {
		setIsModalVisible(true);
	};

	function smsMarkedAsSended() {
		let url =
			process.env.REACT_APP_API_GATEWAY_URL +
			magasin +
			"/reservation/" +
			resa.Vente +
			"/sms";
		fetch(url, {
			method: "PUT",
			headers: {
				Authorization: "Bearer " + token,
			},
		})
			.then((response) => {
				return response;
			})
			.then((res) => {
				window.location.reload(false);
				return res;
			});
	}

	const getLignes = (magasin) => {
		let lignes = [];
		let resaItems = resa.Ligne !== undefined ? resa.Ligne : resa.Lignes;
		resaItems.forEach((ligne) => {
			lignes.push(ligne.BarCode + " Qté : " + ligne.Quantite + "\n");
		});
		switch (magasin) {
			case "1740349":
				return (
					firstMsg("ZAGO Annecy") +
					lignes.toString() +
					getSecondMsg(magasin)
				);
			case "1740404":
				return (
					firstMsg("ZAGO Annemasse") +
					lignes.toString() +
					getSecondMsg(magasin)
				);
			case "1690155":
				return (
					firstMsg("ZAGO St Priest") +
					lignes.toString() +
					getSecondMsg(magasin)
				);
			case "1740419":
				return (
					firstMsg("ZAGO Sallanches") +
					lignes.toString() +
					getSecondMsg(magasin)
				);
			case "1740372":
				return (
					firstMsg("Grenier Alpin Seynod") +
					lignes.toString() +
					getSecondMsg(magasin)
				);
			default:
				return (
					firstMsg("ZAGO") +
					lignes.toString() +
					getSecondMsg("1740349")
				);
		}
	};

	// is a valid telephone number?
	const isValidPhoneNumber = (phoneNumberString) => {
		let cleaned = (phoneNumberString || "").replace(/\D/g, "");
		let matchFrench06 = cleaned.match(/^(0033|6)[1-9](\d{2}){4}$/);
		let matchFrench07 = cleaned.match(/^(0033|7)[1-9](\d{2}){4}$/);
		let matchSuisse = cleaned.match(/^(0041|7)[1-9](\d{2}){4}$/);
		return (
			(!!matchSuisse && matchSuisse[0] === cleaned) ||
			(!!matchFrench06 && matchFrench06[0] === cleaned) ||
			(!!matchFrench07 && matchFrench07[0] === cleaned)
		);
	};

	function getMobile() {
		let mobile = "";
		if (resa.Portable !== null && resa.Portable !== "") {
			if (
				resa.Portable.startsWith("06") ||
				resa.Portable.startsWith("07")
			) {
				mobile =
					"0033" +
					resa.Portable.substring(1)
						.trim()
						.replace(/\s/g, "")
						.replaceAll(".", "");
			} else if (resa.Portable.startsWith("+")) {
				mobile = resa.Portable.trim()
					.replace(/\s/g, "")
					.replace("+", "00")
					.replaceAll(".", "");
			} else if (resa.Portable.startsWith("0041")) {
				mobile = resa.Portable.trim()
					.replace(/\s/g, "")
					.replaceAll(".", "");
			}
		}
		return mobile;
	}

	// create a random string id
	function randomString(length) {
		let result = "";
		let characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * charactersLength)
			);
		}
		return result;
	}

	function smsSended() {
		let url =
			process.env.REACT_APP_API_GATEWAY_URL +
			magasin +
			"/reservation/" +
			resa.Vente +
			"/sms";
		fetch(url, {
			method: "PUT",
			headers: {
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				return response.clone().json();
			})
			.then((res) => {
				return res;
			});
	}

	const handleOk = async (_e) => {
		let mobile = getMobile();
		if (isValidPhoneNumber(mobile)) {
			console.log(magasin);
			let urlApi =
				process.env.REACT_APP_API_GATEWAY_URL +
				magasin +
				"/reservation/" +
				resa.Vente +
				"/sms";
			let messageJson = {
				recipient: {
					to: mobile,
				},
				body: {
					text: getLignes(magasin),
					encoding: "GSM7",
				},
				from: emetteur.slice(0, 10),
			};
			fetch(urlApi, {
				method: "POST",
				headers: {
					Authorization: "Bearer " + token,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(messageJson),
			})
				.then((response) => response.json())
				.then((data) => {
					console.log("Success:", data);
				})
				.catch((error) => {
					console.error("Error:", error);
				});
			smsSended();
			smsMarkedAsSended();
			setIsModalVisible(false);
		}
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const title =
		resa.Vente + " : " + resa.Nom + " " + resa.Prenom + " - " + getMobile();

	const smsContent = () => {
		let resaItems = resa.Ligne !== undefined ? resa.Ligne : resa.Lignes;
		return (
			<div>
				<p>
					{firstMsg(
						magasin === "1740404" ? "ZAGO Annemasse" : "ZAGO"
					)}
				</p>
				{resaItems.map((ligne) => {
					return (
						<p key={ligne.BarCode + randomString(10)}>
							{"• " + ligne.BarCode + " : " + ligne.Quantite}
						</p>
					);
				})}
				<p>{getSecondMsg(magasin)}</p>
			</div>
		);
	};

	return (
		<>
			{getMobile() !== null && getMobile() !== "" && !resa.sms ? (
				<Button type="warning" onClick={showModal}>
					<span role="img" aria-label="phone">
						📱
					</span>{" "}
					SMS
				</Button>
			) : null}
			<Modal
				title={title}
				open={isModalVisible}
				onCancel={handleCancel}
				footer={[
					<Button
						icon={<CheckOutlined />}
						key="back"
						onClick={handleCancel}
					>
						Return
					</Button>,
					<Button
						key="submit"
						icon={<MailOutlined />}
						type="primary"
						onClick={handleOk}
					>
						Envoyer votre SMS
					</Button>,
				]}
				width={700}
			>
				{smsContent()}
			</Modal>
		</>
	);
};

export default ModalSMS;
