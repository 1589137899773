export const magasins = {
	"zago-annemasse": {
		marque: "ZAGO",
		magcode: "1740404",
		nom: "ZAGO ANNEMASSE",
		horaire:
			"Du lundi au vendredi de 10h00 à 12h00 et de 13h30 à 17h30 et le samedi de 10h00 à 12h00",
	},
	"zago-annecy": {
		marque: "ZAGO",
		magcode: "1740349",
		nom: "ZAGO ANNECY",
		horaire:
			"Du lundi au vendredi de 10h00 à 12h30 et de 14h à 19h et le samedi de 10h00 à 19h00",
	},
	"zago-st-priest": {
		marque: "ZAGO",
		magcode: "1690155",
		nom: "ZAGO ST PRIEST",
		horaire: "Du lundi à samedi de 10h à 19h",
	},
	"zago-sallanches": {
		marque: "ZAGO",
		magcode: "1740419",
		nom: "ZAGO SALLANCHES",
		horaire:
			"Du lundi au vendredi de 10h00 à 12h30 et de 14h à 19h et le samedi de 10h00 à 19h00",
	},
	"grenier-seynod": {
		marque: "GRENIER ALPIN",
		magcode: "1740372",
		nom: "GRENIER ALPIN SEYNOD",
		horaire: "Du mardi au samedi de 10h00 à 12h00 et de 14h à 19h",
	},
};
