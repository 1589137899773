import React from "react";
import { useAsyncDebounce } from "react-table";
import { Button } from "antd";
import { AiFillCloseCircle, AiOutlineReload } from "react-icons/ai";

export const GlobalFilter = ({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
	lastUpdate,
}) => {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = React.useState(globalFilter);
	const magasinsList = [
		["1740404", "1740349", "1690155", "1740419"],
		["1740372"],
	];
	const magasin = window.location.pathname.split("/")[1];

	const onSearch = useAsyncDebounce((val) => {
		setGlobalFilter(val || undefined);
	}, 1500);

	async function updateResas() {
		let brand = "";
		console.log();
		if (Object.values(magasinsList)[0].includes(magasin)) {
			brand = "zago";
			await fetch(
				process.env.REACT_APP_API_RESA_UPDATE_URL +
					brand +
					"/" +
					magasin
			).then((res) => {
				setTimeout(() => {
					window.location.reload(false);
				}, 3000);
				return res.json();
			});
		} else if (Object.values(magasinsList)[1].includes(magasin)) {
			brand = "grenier-alpin";
			await fetch(
				process.env.REACT_APP_API_RESA_UPDATE_URL +
					brand +
					"/" +
					magasin
			).then((response) => {
				setTimeout(() => {
					window.location.reload(false);
				}, 3000);
				return response.json();
			});
		}
	}

	return (
		<div
			style={{
				display: "grid",
				justifyContent: "space-between",
				gridTemplateColumns: "repeat(3, 1fr)",
				gridGap: "10px",
			}}
		>
			<div
				style={{
					gridColumn: "1/4",
					backgroundColor: "#fff",
					padding: 15,
					borderRadius: 10,
					marginBottom: 15,
				}}
			>
				<div style={{ padding: 5 }}>
					<button
						title="Reset"
						style={{
							backgroundColor: "white",
							border: 0,
							cursor: "pointer",
						}}
						onClick={() => onSearch("")}
					>
						<AiFillCloseCircle
							size={20}
							style={{ verticalAlign: "sub" }}
						/>
					</button>
					<input
						value={value}
						onChange={(e) => {
							setValue(e.target.value);
							onSearch(e.target.value);
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								onSearch(value);
							}
						}}
						placeholder={`Rechercher ${count} lignes...`}
						style={{
							width: "92%",
							fontSize: "1.1rem",
							border: "0",
							backgroundColor: "#EEE",
							borderRadius: "8px",
							padding: "0.2rem",
						}}
					/>
				</div>
			</div>
			<div
				style={{
					gridColumn: "4/4",
					backgroundColor: "#fff",
					padding: (0, 10),
					textAlign: "center",
					fontWeight: "bold",
					borderRadius: 10,
					marginBottom: 15,
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Button
					style={{ borderRadius: 10, backgroundColor: "lightgreen" }}
					onClick={() => updateResas()}
				>
					<AiOutlineReload
						size={18}
						style={{ verticalAlign: "sub" }}
					/>
				</Button>
				<>Dernière mise à jour: {lastUpdate}</>
			</div>
		</div>
	);
};
